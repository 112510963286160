import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AccessControlApiService } from './api/accessControl.service';
import { AccountApiService } from './api/account.service';
import { ActivityApiService } from './api/activity.service';
import { AffiliateApiService } from './api/affiliate.service';
import { AnnouncementApiService } from './api/announcement.service';
import { AppStatusApiService } from './api/appStatus.service';
import { BannedWordApiService } from './api/bannedWord.service';
import { CaptchaApiService } from './api/captcha.service';
import { CertificateApiService } from './api/certificate.service';
import { ChatMessageNoticeItemApiService } from './api/chatMessageNoticeItem.service';
import { FavoriteApiService } from './api/favorite.service';
import { FavoriteItemApiService } from './api/favoriteItem.service';
import { FavoriteNoticeItemApiService } from './api/favoriteNoticeItem.service';
import { FileApiService } from './api/file.service';
import { ImageApiService } from './api/image.service';
import { InquiryApiService } from './api/inquiry.service';
import { InterUserAccessControlStateItemApiService } from './api/interUserAccessControlStateItem.service';
import { LikeApiService } from './api/like.service';
import { LikeItemApiService } from './api/likeItem.service';
import { LikeNoticeItemApiService } from './api/likeNoticeItem.service';
import { MessageApiService } from './api/message.service';
import { MessageReminderApiService } from './api/messageReminder.service';
import { PersonalMemoApiService } from './api/personalMemo.service';
import { PersonalMemoItemApiService } from './api/personalMemoItem.service';
import { PostApiService } from './api/post.service';
import { PostItemApiService } from './api/postItem.service';
import { ProfileApiService } from './api/profile.service';
import { ReportApiService } from './api/report.service';
import { ScheduleApiService } from './api/schedule.service';
import { StatisticsApiService } from './api/statistics.service';
import { SubscriptionApiService } from './api/subscription.service';
import { SystemReportApiService } from './api/systemReport.service';
import { UserImageApiService } from './api/userImage.service';
import { UserItemApiService } from './api/userItem.service';
import { UserNotificationApiService } from './api/userNotification.service';
import { VisitorHistoryApiService } from './api/visitorHistory.service';
import { VisitorHistoryItemApiService } from './api/visitorHistoryItem.service';
import { VisitorHistoryNoticeItemApiService } from './api/visitorHistoryNoticeItem.service';
import { WithdrawalFormApiService } from './api/withdrawalForm.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
